@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

body {
  font-weight: 400;
  font-family: 'Prompt-Regular';
}

.period td, .period th {
  padding: .55rem !important;
}

.award-current-box {
  z-index: 2;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
}

.footer {
  width: 100%;
  background-color: #0077B6;
  color: white;
  text-align: center;
  font-size: 16px !important;
}